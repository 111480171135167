body {
  margin: 0;
  font-family: 'Poppins', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F8F8F8;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* login */
.login{
  background-color: #ffffff;
  width: 600px;
  padding: 50px;
  border-radius: 20px;
}
.login-form{
  margin-bottom: 20px;
}
.login-form:nth-child(3n) img{
  cursor: pointer;
}

.login-form{
  border: solid 1px #707070;
  background-color: #F9F9F9;
  color: #000000;
  border-radius: 50px;
  padding-left: 20px;
  display: flex;
  gap: 10px;
}

.login-form input{
  border-radius: 0 50px 50px 0 !important;
  border: none !important;
  width: calc(100% - 0px) !important;
  margin: 0 !important;
}

.login-form img{
  width: 20px;
}

.boton-login{
  background-color: #BD1F60;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  width: 100%;
  border-radius: 50px;
  border: solid 2px #BD1F60;
  font-size: 20px;
  padding: 0px;
  cursor: pointer;
  transition: all ease-in-out 0.3s;
}
.boton-login:hover{
  background-color: #ffffff;
  color: #BD1F60;
  transition: all ease-in-out 0.3s;
}
/* login */

/* sidenav */
.sidenav-custom{
  background-color: #ffffff;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidenav-custom > div{
  width: 50%;
}
.sidenav-title > p {
  color: #BD1F60;
  font-size: 25px;
  font-weight: bold;
  margin: 0;
}
.sidenav-user{
 display: flex;
 margin-left: 50px;
}
.sidenav-user div{
  margin-left: 10px;
}
.sidenav-user p{
  margin: 0;
}

.sidenav-opcion{
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #BBBBBB;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.sidenav-opcion svg{
  width: 25px;
  height: 25px;
  margin-right: 20px;
}
.sidenav-opcion:hover{
  color: #BD1F60;
  transition: all 0.3s ease-in-out;
}
.sidenav-opcion:hover  path{
  fill: #BD1F60;
  transition: all 0.3s ease-in-out;
}
/* sidenav */

/* botones */
button{
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  font-size: 15px;
  font-family: 'Poppins', Courier, monospace;
  height: 41px;
  cursor: pointer;
}

.boton-morado{
  background-color: #A541AD;
  color: #ffffff;
  font-weight: bold;
  font-size: 15px;
  padding: 10px;
  border-radius: 15px;
  width: fit-content;
  height: 41px;
}

.boton-rojo{
  background-color: #D62B2B;
  color: #ffffff;
  font-weight: bold;
  font-size: 15px;
  padding: 10px;
  border-radius: 15px;
  width: fit-content;
  height: 41px;
}
.boton-rojo:focus{
  background-color: #D62B2B;
}

.boton-verde{
  background-color: #39C93E;
  color: #ffffff;
  font-weight: bold;
  font-size: 15px;
  padding: 10px;
  border-radius: 15px;
  width: fit-content;
  height: 41px;
}
.boton-verde:focus{
  background-color: #39C93E;
}

.boton-azul{
  background-color: #2A79AE;
  color: #ffffff;
  font-weight: bold;
  font-size: 15px;
  padding: 10px;
  border-radius: 15px;
  width: fit-content;
  height: 41px;
}
/* botones */

/* titulos y textos */
.titulo-1{
  font-size: 22px;
  color: #000000;
  font-weight: bolder;
}

.texto-1{
  color: #747678;
  font-weight: bolder;
  font-size: 20px;
}
/* titulos */

/* fondos */
.contenedor-1{
  background-color: #ffffff;
  padding: 20px 50px;
  margin: 10px 0px !important;
  height: calc(100vh - 110px);
  max-height: calc(100vh - 110px);
  overflow: auto;
}
/* fondos */


/* tabla */
.tabla-header{
  color: #707070;
  font-weight: bolder;
  position: relative;
  /* left: -15px; */
}

.tabla-body{
  color: #000000;
  font-weight: bolder;
}
/* tabla */


.sombra{
  -webkit-box-shadow: 0px 24px 30px -12px rgba(0,0,0,0.41);
  -moz-box-shadow: 0px 24px 30px -12px rgba(0,0,0,0.41);
  box-shadow: 0px 24px 30px -12px rgba(0,0,0,0.41);
}


/* formulario */
input:not([type]):focus:not([readonly])+label,
input[type=text]:not(.browser-default):focus:not([readonly])+label,
input[type=password]:not(.browser-default):focus:not([readonly])+label,
input[type=email]:not(.browser-default):focus:not([readonly])+label,
input[type=url]:not(.browser-default):focus:not([readonly])+label,
input[type=time]:not(.browser-default):focus:not([readonly])+label,
input[type=date]:not(.browser-default):focus:not([readonly])+label,
input[type=datetime]:not(.browser-default):focus:not([readonly])+label,
input[type=datetime-local]:not(.browser-default):focus:not([readonly])+label,
input[type=tel]:not(.browser-default):focus:not([readonly])+label,
input[type=number]:not(.browser-default):focus:not([readonly])+label,
input[type=search]:not(.browser-default):focus:not([readonly])+label,
textarea.materialize-textarea:focus:not([readonly])+label{
  color: #2A79AE;
}

input:not([type]):focus:not([readonly]),
input[type=text]:not(.browser-default):focus:not([readonly]),
input[type=password]:not(.browser-default):focus:not([readonly]),
input[type=email]:not(.browser-default):focus:not([readonly]),
input[type=url]:not(.browser-default):focus:not([readonly]),
input[type=time]:not(.browser-default):focus:not([readonly]),
input[type=date]:not(.browser-default):focus:not([readonly]),
input[type=datetime]:not(.browser-default):focus:not([readonly]),
input[type=datetime-local]:not(.browser-default):focus:not([readonly]),
input[type=tel]:not(.browser-default):focus:not([readonly]),
input[type=number]:not(.browser-default):focus:not([readonly]),
input[type=search]:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]){
  border-bottom: 1px solid #2A79AE;
  -webkit-box-shadow: 0 1px 0 0 #2A79AE;
  box-shadow: 0 1px 0 0 #2A79AE;
}

[type="radio"]:checked+span:after,
[type="radio"].with-gap:checked+span:after{
  background-color: #2A79AE;
}

[type="radio"]:checked+span:after,
[type="radio"].with-gap:checked+span:before,
[type="radio"].with-gap:checked+span:after{
  border: 2px solid #2A79AE;
}

.select-dropdown li.disabled, 
.select-dropdown li.disabled>span, 
.select-dropdown li.optgroup{
  color: #2A79AE;
}
.select-wrapper input
.select-dropdown:focus {  
  border-bottom: 2px solid #2A79AE;
}
.dropdown-content li>a,
.dropdown-content li>span {  
  color: #2A79AE;
}


/* ventas */
.tabla-venta{
  cursor: pointer;
  transition: all linear 0.3s;
}

.tabla-venta:hover{
  transition: all linear 0.3s;
  background: #47474741;
}


/* clientes */
.contenedor-cliente{
  border: solid 1px #6d6d6d;
  border-radius: 15px;
  width: calc(50% - 15px);
  padding: 0px 10px;
}